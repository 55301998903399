import ClassAPI from "./classAPI";

class ClassStudentAPI extends ClassAPI {
    constructor () {
        super('student')
    }

    async performance () {
        const ret = await this.get({url: 'performance'})
        return (ret) ? ret : [];
    }
}

const  studentAPI  = new ClassStudentAPI()

export default studentAPI;

