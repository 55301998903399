import { message } from "antd";
import { $authHost } from "./index";

export const fetchSkillStarData = async (studentID, tacts = '') => {
    try {
//        const { data } = await $authHost.get('api/teacher/star/?studentID=' + studentID + '&tacts=' + tacts)
        const { data } = await $authHost.get('api/tutor/star/?studentID=' + studentID + '&tacts=' + tacts)
        return data
    } catch {
        message.error("Ошибка загрузки субьектной звезды")
        return null
    }
}

export const modyfySkillStarData = async (formData) => {
    const { data } = await $authHost.put('api/tutor/star/', formData)
    return data
}

