import { Card } from "antd";
import { FC, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { useTable } from "react-table";
import serviceAPI from "../../../http/classServiceAPI";
import studentAPI from "../../../http/classStudentAPI";

interface Trecord {
    key?: string,
    id?: string,
    accessor?: string,
    Header?: string,
    columns?: Trecord[] | [],
    style?: React.CSSProperties
}

const styleTable = { background: '#e0e0e0', padding: '3px', border: 'solid 1px gray' };
const styleCell = { border: '1px solid gray', textAlign: 'center', cursor: 'pointer', }
const styleCellHover = { border: '1px solid gray', textAlign: 'center', cursor: 'pointer', background: "#ffd110", }

//const servicesAPI = new ClassAPI('services');

export const loaderStudentPerformance = async ({ params }: any) => {
    //console.log('=events loader===================================', params);
    /* const events_raw = await fetchEvents2()
    const eventTypes = await fetchCalendarEventTypes();
    const colors = eventTypes.map((item: any) => { return { value: item.id, label: item.name, color: item.color } });
    const events = events_raw.map((event: any) => { return { ...event, calendareventtype: eventTypes.find((eventtype: any) => eventtype.id == event.type) } })

    return { events, eventTypes, colors } */
    const raw_tacts = await serviceAPI.tacts();
    const tacts = raw_tacts.map((item: any) => { return { Header: item?.name, accessor: 'tact_' + item?.id, style: styleTable } })

    const data = await studentAPI.performance();

    //console.log("raw_data", raw_data);


    const columns: Trecord[] = [{
        Header: '   ',
        columns: [{
            Header: 'Предметы',
            accessor: 'subject',
            style: styleTable,
        }, ...tacts]
    },];

    //const data: any = [{ id: 1, subject: "Русский", tact_8: "10", tact_11: "10" }, { id: 2, subject: "Математик", tact_9: "5", tact_10: "1" }];

    return { columns, data }
}

export async function actionStudentPerformance({ params, request }: any) {
    /*     const formData = await request.formData();
        const { event } = Object.fromEntries(formData);
        if (event) {
            const event2 = JSON.parse(event)
            //console.log("eventsTable2", event, event2);
            modyfyEventO(event2)
        }
    
    
        return { event } */
    return null
}



export const StudentPerformance: FC = () => {
    const { columns, data } = useLoaderData() as any;

    const tableInstance = useTable({
        columns,
        data,
    })

    const { getTableProps, getTableBodyProps, headerGroups, /* footerGroups, */ rows, prepareRow } = tableInstance


    const [editRowId, setEditRowId] = useState();
    const [editColumnId, setEditColumnId] = useState();

    const handleEditClick = (rowId: any) => {
        //  console.log("edit click", rowId);

        setEditRowId(rowId.row);
        setEditColumnId(rowId.column);
    };


    return (
        <Card key={'performance'}>
            <table {...getTableProps()} key={'performance_table'}>
                <thead>
                    {headerGroups.map((headerGroup: any) => (
                        <tr key={Math.random()} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column: any) => (
                                <th
                                    {...column.getHeaderProps([{
                                        style: column.style
                                    }])}
                                >
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row: any) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell: any) => {
                                    if (cell.column.id === 'level') {
                                        return <td></td>
                                    } else
                                        return <td {...cell.getCellProps()} /* style={{ ...{ border: '1px solid gray' },  ...cell.column.style  }} */ onMouseLeave={() => handleEditClick({ column: 0, row: 0 })} onMouseEnter/* Click */={() => handleEditClick({ column: cell.column.id, row: cell.row.original.id })/* () => { console.log(cell.column.id, cell.row.original) } */}  {...(cell.column.id === 'subject') ? ({ style: { ...{ border: '1px solid gray' }, ...cell.column.style } }) : ((cell.column.id === editColumnId && cell.row.original.id === editRowId) ? { style: styleCellHover } : { style: styleCell })}>
                                            {
                                                //console.log("-->", cell)
                                            }
                                            {(cell.column.editable/* editRowId === row.id *//* cell.column.id === editColumnId && cell.row.original.id === editRowId */) ? (
                                                /* Render custom input field for editing */
                                                <>
                                                    {/* <CustomInputComponent
                                                        cell={cell}
                                                        //value={cell.value}
                                                        onChange={(newValue: any) => {
                                                            // Logic to update local state with the new value
                                                        }}
                                                    /> */}
                                                    {/* <button onClick={() => handleSaveClick(row.id, row.values)}>Save</button>
                      <button onClick={() => handleCancelClick(row.id)}>Cancel</button> */}
                                                </>
                                            ) : (
                                                /* Render static text */
                                                <div /* onClick={() => handleEditClick({ column: cell.column.id, row: cell.row.original.id })} */>
                                                    {cell.render('Cell')}
                                                    {/* <button onClick={() => handleEditClick(row.id)}>Edit</button> */}
                                                </div>
                                            )
                                            }
                                        </td>
                                })}
                            </tr>
                        )
                    })
                    }
                </tbody>
            </table>
        </Card>
    )
}
