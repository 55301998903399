//import { connect } from 'http2';
import { useState, useContext, FC } from 'react';
import { Button } from "react-bootstrap";
//import Card from "react-bootstrap/Card";
import { redirect, useLocation, useSubmit } from 'react-router-dom';
import { LOGIN_ROUTE, ADMIN_ROUTE } from '../utils/consts';
import { Context, store } from "../index";
import { useNavigate, Form } from 'react-router-dom';
import { Card, Col, Row } from 'antd';

export async function actionLogin({ request, params }: any) {
  const formData = await request.formData();
  const { login, password, fromPage } = Object.fromEntries(formData);
  //  console.log("auth.tsx action", fromPage);

  const response = await store.login(login, password);
  //console.log("actionLogin", response);

  if (fromPage) return redirect(fromPage)
  else return redirect('..');
}

const Auth: FC = () => {

  const submit = useSubmit();
  const { store } = useContext(Context);

  const location = useLocation();
  const navigate = useNavigate();
  //  const {signin} = useAuth();

  const fromPage = location.state?.from?.pathname || '/';

  const isLogin = location.pathname === LOGIN_ROUTE

  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')

  /* const signIn = async (event: any) => {
    try {
      event.preventDefault();
      store.login(login, password);
      //      user.login(login, password);
      console.log("Auth.tsx signin", fromPage);


      if (fromPage === 'notfound') navigate('/login', { replace: true });
      if (fromPage != 'login') navigate(fromPage, { replace: true });


    } catch (e) {
      // alert(e?.response?.data?.message)
    }
  } */

  const onClick = async () => {
    console.log("new event", login, password);
    let formData = new FormData();
    //formData.append("event", JSON.stringify({}));
    formData.append("login", login);
    formData.append("password", password);
    formData.append("fromPage", fromPage);
    submit(formData, { method: "POST" });
    return redirect('..')
  }
  
  const handleKeyPress = (event:any) => {
    if(event.key === 'Enter'){
      onClick();
      //console.log('Нажата клавиша Enter, ура!');
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center" style={{ height: window.innerHeight - 54 }} >
      <Card style={{ width: 600 }} className="p-5" title={<h2 className="m-auto">Авторизация</h2>}>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <input id='login' style={{ width: '100%' }} placeholder="Введите ваш логин..." onChange={(e) => setLogin(e.target.value)} />
          </Col>
          <Col span={24}>
            <input id='password' style={{ width: '100%' }} placeholder="Введите ваш пароль..." type="password" onChange={(e) => setPassword(e.target.value)} /* onKeyPress={handleKeyPress} */ onKeyDown={handleKeyPress}/>
          </Col>
          <Col span={24}>
            <Button type="submit" style={{ justifySelf: 'end' }} /* className='mt-3 align-self-end' */ onClick={onClick}>Войти</Button>
          </Col>

        </Row>
      </Card>
    </div>
  );
};

export default Auth;
