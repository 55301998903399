import { Button } from "antd";
import localForage from "localforage";
import fileAPI from "../http/classFileAPI";
import { useSubmit } from "react-router-dom";


export const CopyFiles = (params: any) => {
    const { fileList, id, area } = params;

    const copyFiles = async (value: any) => {
        console.log("copy files", fileList, id, area);
        localForage.setItem('CopyFilesFileList', fileList);

    }


    return (
        <Button onClick={copyFiles}>Копировать файлы</Button>
    )
}

export const PasteFiles = (params: any) => {
    const { fileList, id, area } = params;
    const submit = useSubmit();

    const copyFiles = async (value: any) => {
        const fileList = await localForage.getItem('CopyFilesFileList');
        console.log("paste files", fileList, id, area);
        fileAPI.clone({ fileList, id, area })

        let formData = new FormData();
        submit(formData, { method: "GET", });
    }


    return (
        <Button onClick={copyFiles}>Вставить файлы</Button>
    )
}