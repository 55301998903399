
import { redirect, useLoaderData, useSubmit } from "react-router-dom"
import Select from 'react-select'
import styled from 'styled-components'
import { fetchSkillTacts } from "../http/studentApi";



export async function selectSkillTactsLoader(params: any) {
    const selectedStudent = Number(params?.params['selectedStudent'] || 0);
    const selectedSkillTacts = String(params?.params['selectedSkillTacts'] || '');
    const rawDates = await fetchSkillTacts(selectedStudent);
    const tacts = rawDates  //.map((_date: any) => { return ({ value: _date.id, label: _date.name }) })
    //console.log("selectDateLoader", params, classes);
    return { tacts, selectedSkillTacts, selectedStudent };
}

export async function selectSkillTactsAction({ params, request }: any) {
    const formData = await request.formData();

/*     const { query } = Object.fromEntries(formData);
    if (query) console.log("modules2Action", JSON.parse(query));

 */    const { _tacts } = Object.fromEntries(formData);
    if (_tacts) return redirect(`${_tacts}`);
    //console.log("2", _class);

    /*   console.log("modules actions: ", params, classsubject); */
    return redirect('.')
}

interface SelectOptions {
    value: number
    label: string
}

const StyledSelect = styled(Select)`
    width: fit-content;
    block-size: fit-content;
    z-index: 99999;
    margin: 4px;
`;

export const SelectSkillTacts = () => {
    const submit = useSubmit();
    const { tacts, selectedSkillTacts, selectedStudent } = useLoaderData() as { tacts: SelectOptions[], selectedSkillTacts: string, selectedStudent: number };
    //console.log("SelectSkillTacts", tacts, selectedSkillTacts, selectedStudent);


    const onSelect1 = (e: any) => {
        //console.log(e);
        let formData = new FormData();
        formData.append("_tacts", e?.value);
        formData.append("_student", `${selectedStudent}`);
        submit(formData, { method: "POST" });
    }

    if (tacts) return (
        <StyledSelect
            onChange={onSelect1}
            name="tacts"
            placeholder='Выберите такт'
            value={tacts.filter((item: any) => item.value === selectedSkillTacts)}
            options={tacts}
        />
    )

    return (<></>)
}
