import React, { useContext, useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { observer } from "mobx-react-lite";
import { Context } from '../..';
import { Container } from 'react-bootstrap';
import { BiEnvelope } from 'react-icons/bi';
import { fetchSchedule, fetchScheduleGrid } from '../../http/adminApi';
import { Card } from 'antd';

//Расписание уроков
const StudentsShedule = observer(({ inputSchedule, mode }) => {
    console.log("mode->", mode);
    
    const { student } = useContext(Context)
    const { admin } = useContext(Context)
    const { parent } = useContext(Context)

    const [schedule, setSchedule] = useState([])
    const [lessonsIntervals, setLessonsIntervals] = useState([])

    useEffect(() => {
        loadData()
    }, [])

    useEffect(() => {
        loadData()
    }, [parent.studentClass])

    const loadData = async () => {
        if (inputSchedule) {
            setSchedule(inputSchedule)
        }

        if (mode == 'student') {
            /*             const schedule 
                        await fetchScheduleGrid(student.studentClass).then(
                            data => {
                                setLessonsInterval(data)
                                schedule = await fetchSchedule(student.studentClass)
                                setSchedule(schedule)
                            }) */
            const lessonsInt = await fetchScheduleGrid(student.studentClass)
            setLessonsIntervals(lessonsInt)
            const data = await fetchSchedule(student.studentClass)
            setSchedule(data)
        }

        if (mode == 'parent') {
            if (parent.studentClass) {
                const lessonsInt = await fetchScheduleGrid(parent.studentClass)
                setLessonsIntervals(lessonsInt)
                const data = await fetchSchedule(parent.studentClass)
                setSchedule(data)
            }
/*             const lessonsInt = await fetchScheduleGrid(parent.studentClass)
            setLessonsIntervals(lessonsInt)
            const data = await fetchSchedule(parent.studentClass)
            setSchedule(data) */

            /*             const lessonsIntervals = await fetchScheduleGrid(student.studentClass)
                        admin.setLessonsInterval(lessonsIntervals) */
        }
    }

    const days = [
        { day: 1 }, { day: 2 }, { day: 3 }, { day: 4 }, { day: 5 },
    ]

    function getStudentScheduleName(day, intID) {
        if (schedule) {
            return schedule.filter(studentSchedule => (studentSchedule.day === day && studentSchedule.intID === intID))[0]?.subject?.name
        } else {
            return ""
        }
        
        //  return student.studentSchedule.filter(studentSchedule => (studentSchedule.day === day && studentSchedule.intID === intID))[0]?.subject?.name
    }

    function currentDay(day) {
        var d = new Date();
        var n = d.getDay();
        if (n === day) {
            return true
        } else {
            return false
        }
    }

    function currentTime(time) {
        var ret = false
        var now = new Date();
        var begin = new Date();
        var end = new Date();
        var h1 = time.split(" - ")[0].split(':')[0]
        var m1 = time.split(" - ")[0].split(':')[1]
        var h2 = time.split(" - ")[1].split(':')[0]
        var m2 = time.split(" - ")[1].split(':')[1]
        begin.setHours(h1, m1)
        end.setHours(h2, m2)
        if (begin <= now && now < end) {
            ret = true
        }
        return ret
    }

    return (
        <Card>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Время</th>
                        <th>Понедельник</th>
                        <th>Вторник</th>
                        <th>Среда</th>
                        <th>Четверг</th>
                        <th>Пятница</th>
                    </tr>
                </thead>
                <tbody>
                    {lessonsIntervals.map(lessonsInterval =>
                        <tr key={'tr' + lessonsInterval.id}
                            style={currentTime(lessonsInterval.name) ? { backgroundColor: 'hsl(47, 100%, 95%)' } : {}}
                        >
                            <td>
                                {lessonsInterval.name}
                            </td>
                            {days.map(day =>
                                <td key={'td' + day.day + lessonsInterval.id}
                                    style={currentDay(day.day) ? { backgroundColor: 'hsl(47, 100%, 95%)' } : {}}
                                >
                                    {getStudentScheduleName(day.day, lessonsInterval.id) || ""}
                                </td>
                            )}
                        </tr>
                    )}
                </tbody>
            </Table>
        </Card>
    );
});

export default StudentsShedule;