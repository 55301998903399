
import { redirect, useLoaderData, useSubmit } from "react-router-dom"
import Select from 'react-select'
import styled from 'styled-components'
import { fetchClasses, studentsByClassList } from "../http/adminApi"



export async function selectStudentLoader(params: any) {
    const selectedStudent = Number(params?.params['selectedStudent'] || 0);
    const selectedClass = Number(params?.params['selectedClass'] || 0);
    const rawStudent = await studentsByClassList(selectedClass);
    const students = rawStudent.map((_student: any) => { return ({ value: _student.id, label: _student.name }) })
    //console.log("selectStudentLoader", params, students);
    return { students, selectedStudent };
}

export async function selectStudentAction(/* { params, request } */params: any) {
    //console.log("3-->>>", params.request);

    const formData = await params?.request?.formData();
    /*     console.log("1", formData);
        const { query } = Object.fromEntries(formData);
        if (query) console.log("StudentAction", JSON.parse(query));
     */
    const { _student } = Object.fromEntries(formData);
    if (_student) return redirect(`${_student}`);

    /*   console.log("modules actions: ", params, classsubject); */
    return redirect('.')
}

interface SelectOptions {
    value: number
    label: string
}

const StyledSelect = styled(Select)`
    width: fit-content;
/*     width: "max-content",
    minWidth: "100%" */
    block-size: fit-content;
    z-index: 99999;
    margin: 4px;
`;

export const SelectStudent = () => {
    const submit = useSubmit();
    const { students, selectedStudent } = useLoaderData() as { students: SelectOptions[], selectedStudent: number };
    //console.log(classes, selectedStudent);


    const onSelect1 = (e: any) => {

        let formData1 = new FormData();
        formData1.append("_student", e?.value);
        //console.log("onSelect1", formData1);
        submit(formData1, { method: "POST" });
    }

    if (students) return (
        <StyledSelect
            /* menuPlacement="auto"
            menuPosition="fixed" */
            onChange={onSelect1}
            name="students"
            placeholder='Выберите студента'
            value={students.filter((item: any) => item.value === selectedStudent)}
            options={students}
        />
    )

    return (<></>)
}
