// Надписи на лучах

function click(col, navigate) {
    if (navigate) {
        navigate(col.key)
        // console.log("COL", col.key, navigate);
    } else {
        // console.log("COLNO!", col.key, navigate);
    }
}

export const caption = (polarToX, polarToY, size, navigate) => col => (
    <a key={`a_caption-of-${col.key}`}
        style={navigate ? { cursor: 'pointer' } : { cursor: 'default' }}
        onClick={() => click(col, navigate)}
    >
        <text
            key={`caption-of-${col.key}`}
            //x={polarToX(col.angle, (size / 2) * 0.86).toFixed(4) - (col.key.length * 10) / 2}
            x={(polarToX(col.angle, (size / 2) * 0.86).toFixed(4) - (col.key.length * 14) / 2) <= -size / 2
                ?
                -size / 2 + 10
                :
                (polarToX(col.angle, (size / 2) * 0.86).toFixed(4) + (col.key.length * 14) / 2) >= size / 2
                    ?
                    size / 2 - 10
                    :
                    polarToX(col.angle, (size / 2) * 0.86).toFixed(4) - (col.key.length * 14) / 2}


            /* x={((col.angle >= 0.0 && col.angle <= 1.0) || (col.angle <= 4.0 && col.angle >= 2.14)) ?
                - (col.key.length * 10) / 2
                :
                (col.angle > 0.0 && col.angle < 3.14) ?
                    -size / 2 + 10
                    :
                    size / 2 - (col.key.length * 10)
            }
 */
            dy={(col.angle >= 3.15 / 2 && col.angle <= 3.15 + 3.15 / 2)
                ?
                // Низ звезды
                (col.angle > 0.0 && col.angle < 3.14) ? 10 : 20 // правая половина звезды
                :
                // Верх звезды
                (col.angle > 0.0 && col.angle < 3.14) ? 0 : -10 // левая половина звезды
            }
            y={polarToY(col.angle, (size / 2) * 0.86).toFixed(4)}
            //  dy={10+ 10 / 2}
            //  dx={-30}
            // dy={(col.angle >1.5 && col.angle <1.6) || (col.angle >4.7 && col.angle <4.8) ? -10 + 10 / 2 : 10 / 2}
            //            dx={(col.angle > 1.0 && col.angle < 2.1) ? -100 : -30}
            /*             dy={(col.angle >= 0.0 && col.angle <= 3.14) ?
                            // если правая половина звезды
                            (col.angle > 1.5 && col.angle < 1.6) ?
                                0 : 5
                            :
                            // если левая половина звезды
                            (col.angle > 4.7 && col.angle < 4.8) ?
                                0 : -20
                        }
             */
            fill="#444"
            fontWeight="450"
        // textShadow="1px 1px 0 #fff"
            //stroke="white"
            //stroke-width="1px"
            filter="url(#shadow2)"
        >
            {/* {polarToX(col.angle, (size / 2) * 0.86).toFixed(4) + (col.key.length * 10) / 2} */}
            {col.key}

        </text>
    </a>
)
