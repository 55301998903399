import ClassAPI from "./classAPI";

class ClassFileAPI extends ClassAPI {
    constructor () {
        super('file')
    }

    async clone (body:any) {
        const ret = await this.post({url: 'clone', body})
        return (ret) ? ret : [];
    }

    async fileList ({area, aid}:any) {
        const ret = await this.get({url: `filelist?area=${area}&aid=${aid}`})
        return (ret) ? ret : [];
    }

}

const  fileAPI  = new ClassFileAPI()

export default fileAPI;

