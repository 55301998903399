import ClassAPI from "./classAPI";

class ClassServicesAPI extends ClassAPI {
    constructor () {
        super('services')
    }

    async tacts() { // получает список тактов
        const ret = await this.get({ url: 'tacts' });
        return (ret) ? ret : [];
    }
}

const  serviceAPI  = new ClassServicesAPI()

export default serviceAPI;

