import ClassAPI from "./classAPI";

class ClassParentAPI extends ClassAPI {
    constructor () {
        super('parent')
    }

    async performance (studentID:number) {
        const ret = await this.get({url: 'performance/'+studentID})
        return (ret) ? ret : [];
    }
}

const  parentAPI  = new ClassParentAPI()

export default parentAPI;

