// линии соединяющие точки

export const shape = (polarToX, polarToY, columns, size) => (chartData, i) => {
    const data = chartData;
    
    const pathDefinition = points => {
        let d = 'M' + points[0][0].toFixed(4) + ',' + points[0][1].toFixed(4);
        for (let i = 1; i < points.length; i++) {
            d += 'L' + points[i][0].toFixed(4) + ',' + points[i][1].toFixed(4);
        }
        return d + 'z';
    };

    let color = `rgb(0,0,0)`
    if (i === 0) {
        color = `#ffd110`
    }
    if (i === 1) {
        color = `#75b53e`
    }
    
    return (
        <path
            key={`shape-${i}`}
            d={pathDefinition(
                columns.map(col => {
                    const value = data[col.key];
                    return [
                        polarToX(col.angle, (value/5 * size) / 2),
                        polarToY(col.angle, (value/5 * size) / 2)
                    ];
                })
            )}
            /* stroke={`#ffd110`} */
            /* stroke={`#75b53e`} */
            stroke={color} 
            strokeWidth="3"            
            strokeOpacity=".5"
            fill="none"
        />
    )
};



