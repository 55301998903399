import React, { useEffect, useContext, useState } from 'react';
import { observer } from "mobx-react-lite";
/* import { Button, Container, Form } from 'react-bootstrap';
import { fetchStarSData } from '../../../http/studentApi';
import RadarChart from '../../utilites/star/RadarChart'; */
import { Context } from '../..';
import { Card, Col, message, Row, Slider } from 'antd';
import { fetchSkillStarData, modyfySkillStarData } from '../../http/tutorApi';
//import TeacherStudentSubjectStarTips from '../../utilites/star/StarTips';
import EditStar from '../utilites/star/EditStar';
import { fetchTipsData } from '../../http/adminApi';
import SelectClassStudent from './SelectClassStudent';
import SkillStars from '../admin/stars/SkillStars';
import { fetchSkillTacts } from '../../http/parentApi';
import { SelectClass, selectClassAction, selectClassLoader } from '../SelectClass';
import { SelectStudent, selectStudentAction, selectStudentLoader } from '../SelectStudent';
import { redirect, useLoaderData, useSubmit } from 'react-router-dom';
import { SelectSkillTacts, selectSkillTactsLoader } from '../SelectSkillTacts';
import { PsychologicalReport } from '../PsychologicalReport';
import fileAPI from '../../http/classFileAPI';

export const tutorSubjectStarLoader = async (params) => {
    const classes = await selectClassLoader(params);
    const students = await selectStudentLoader(params);
    const tacts = await selectSkillTactsLoader(params);
    const selectedSkillTacts = String(params?.params['selectedSkillTacts'] || '');
    const selectedStudent = Number(params?.params['selectedStudent'] || 0);

    const starS = await fetchSkillStarData(selectedStudent, selectedSkillTacts)
    const tipsTextData = await fetchTipsData('tutor')
    //const skillTacts = await fetchSkillTacts(selectedStudent);

    //console.log("TutorSubjectStarLoader", params?.params);
    const fileList = await fileAPI.fileList({ area: 'PsychologicalReport', aid: selectedStudent });
    return { ...classes, ...students, ...tacts, starS, tipsTextData, fileList/* skillTacts */ };
}

export async function tutorSubjectStarAction({ params, request }) {
    //console.log("tutorSubjectStarAction", params);
    const selectedClass = Number(params['selectedClass'] || 0);

    const formData = await request.formData();
    const { _class, _student, _tacts, studentID, starData } = Object.fromEntries(formData);
    if (_class) return redirect(`../subjectstar/${_class}`);
    if (_student && !_tacts) return redirect(`../subjectstar/${selectedClass}/${_student}`);
    if (_tacts) return redirect(`../subjectstar/${selectedClass}/${_student}/${_tacts}`);
    //    await selectClassAction({params, request});
    //await selectStudentAction({params, request});
    if (studentID, starData) {
        //console.log("studentID, starData", studentID, starData);
        modyfySkillStarData({ studentID, starData });
    }

    return redirect('.');
}

const TutorSubjectStar = observer(() => {
    const { admin } = useContext(Context)
    const submit = useSubmit();

    //const [skillTacts, setSkillTacts] = useState([]);

    const { starS, tipsTextData, skillTacts, selectedStudent } = useLoaderData()// as { classes: SelectOptions[], selectedClass: number };
    //console.log(">>>>", starS, tipsTextData, skillTacts, selectedStudent);

    //useEffect(() => {
    //        loadData()
    //}, [admin.selectedStudent])

    //const chartSize = 450;
    //const numberOfScale = 5;

    //const [starS, setStarS] = useState([])

    //  [{ 'Самооценка': 4, 'Самоконтроль': 3, 'Самоорганизация': 3, 'Соц-эмоциональный интеллект': 2, 'Стратегическое мышление': 3, 'Креативное мышление': 3 },
    //  { 'Самооценка': 4, 'Самоконтроль': 3, 'Самоорганизация': 2, 'Соц-эмоциональный интеллект': 3, 'Стратегическое мышление': 2, 'Креативное мышление': 2 }])

    //const [tipsDataName, setTipsDataName] = useState('')
    //const [tipsDataChoice, setTipsDataChoice] = useState(0)
    //const [stateSaveButton, setStateSaveButton] = useState(true)
    //const [tipsTextData, setTipsTextData] = useState({})

    const loadData = async () => {
        console.log("loadData", admin.selectedStudent?.id);
        //const dataS = await fetchStarSData()
        if (admin.selectedStudent?.id) {
            /*             const dataS = await fetchStarSubData(admin.selectedStudent?.id)
                        setStarS(dataS) */
            //            const tips = await fetchTipsData('tutor')
            //            setTipsTextData(tips)
            //            const skillTacts = await fetchSkillTacts(admin.selectedStudent?.id);
            //            setSkillTacts(skillTacts);

        } else {
            //setStarS([])
            //setTipsTextData({})
        }
    }

    /* const handleChangeSkillTacts = async (value) => {
        var data = [[], []];
        if (admin.selectedStudent?.id) {
            data = await fetchSkillStarData(admin.selectedStudent?.id, value)
        }

        setStarS(data);

    };


    const change1 = (name, value) => {
        let star = starS
        star[1][name] = value
        setStarS(JSON.parse(JSON.stringify(star)))
        setTipsDataName(name)
        setTipsDataChoice(value)
        setStateSaveButton(false)
        //    console.log("starS",starS);
    } */

    const saveData = async () => {
        try {
            const formData = new FormData()
            formData.append('studentID', selectedStudent)
            formData.append('starData', JSON.stringify(starS[0]))
            console.log("saveData", starS, selectedStudent);
            submit(formData, { method: "POST" });

            /* modyfyStarSubData(formData).then(data => {
                //console.log("DATA",data,data == 'ok');
                if (data == 'ok') {
                    message.success("Cохранено успешно!")
                    loadData()
                } else {
                    message.error("Не сохранено, ответ сервера:", data)
                    //message.info('This is a normal message');
                }
            }) */
        } catch (error) {
            message.error("Не сохранено, ошибка:", error.message)
        }
    }

    return (<>
        {/* <SelectClassStudent /> */}
        <Card ><div style={{ display: 'flex' }}><SelectClass /><SelectStudent /><SelectSkillTacts /></div></Card>
        <PsychologicalReport isDisabled={false}/>
        <SkillStars
            /* key={"key" + admin.selectedStudent?.id} */
            //studentID={admin.selectedStudent?.id}
            studentID={selectedStudent}
            skillTacts={skillTacts}
            //handleChangeSkillTacts={handleChangeSkillTacts}
            saveData={saveData}
            starS={starS}
            tipsTextData={tipsTextData}
            editable={false}
        />
        {/*         <Card title="Индивидуальный план развития">
            <EditStar
                //header={}
                starS={starS}
                setStarS={setStarS}
                tipsTextData={tipsTextData}
                starLayer={0}
                saveData={saveData}
                greenDesc='- самооценка студента'
                yellowDesc='- оценка тьютора'
            />
        </Card> */}
    </>
    );
});

export default TutorSubjectStar;