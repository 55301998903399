import React, { useContext, useState, useEffect } from 'react';
import { observer } from "mobx-react-lite";
import { Context } from '../../..';

import { fetchStar1Data, fetchStarSData, fetchTacts, modyfyStarEduData, modyfyStarSubData } from '../../../http/studentApi';
import EditStar from '../../utilites/star/EditStar';
import { Card, Select, message } from 'antd';
import { fetchTipsData } from '../../../http/adminApi';

import { fetchSkillTacts, fetchStar1DataBSID, fetchStarSDataBSID, fetchSubjectsTacts } from '../../../http/parentApi';

import { useLoaderData } from 'react-router-dom';
import ParentSkillStars from '../../admin/stars/ParentSkillStars';
import { PsychologicalReport } from '../../PsychologicalReport';
import fileAPI from '../../../http/classFileAPI';

export const parentStarsLoader = async ({ params }) => {
    const selectedStudent = Number(params['studentID'] || 0);

    const fileList = await fileAPI.fileList({ area: 'PsychologicalReport', aid: selectedStudent });

    //console.log("parentStarsLoader", params, selectedStudent, fileList);
    return { selectedStudent, fileList }
}

//Звезды - прогресс студента
const ParentStars = observer((params/* { mode = 'student' } */) => {
    const { student } = useContext(Context)
    const { parent } = useContext(Context)

    const { store } = useContext(Context)
    //const studentID = (params?.studentID) ? params?.studentID : store?.user?.id
    const { studentID } = useLoaderData();
    //const mode = (params?.mode) ? params.mode : 'student'
    const mode = 'parent'
    //console.log("params studentStar", mode, studentID);


    const [starS, setStarS] = useState([])
    const [star1, setStar1] = useState([])
    const [tipsTextDataSub, setTipsTextDataSub] = useState({})
    const [tacts, setTacts] = useState([]);
    const [skillTacts, setSkillTacts] = useState([]);
    const [selectedSkillTacts, setSelectedSkillTacts] = useState([]);


    useEffect(() => {
        loadData()
    }, [parent?.studentData])

    const loadData = async () => {
        let data1
        let dataS
        let datat


        /*         if (mode === 'student') {
                    data1 = await fetchStar1Data('')
                    dataS = await fetchStarSData('')
                    datat = await fetchTacts();
        
                    if (datat) setTacts(datat);
                    if (data1) setStar1(data1);
                    if (dataS) setStarS(dataS);
        
                    const skillTacts = await fetchSkillTacts(studentID);
                    setSkillTacts(skillTacts);
                } else { */
        if (parent?.studentData?.id) {
            data1 = await fetchStar1DataBSID(parent?.studentData?.id)
            dataS = await fetchStarSDataBSID(parent?.studentData?.id)
            datat = await fetchSubjectsTacts(parent?.studentData?.id)
            //console.log("studentStars", dataS, datat)
            const skillTacts = await fetchSkillTacts(parent?.studentData?.id);
            setSkillTacts(skillTacts.map((item) => { return { label: item.name, value: item.date } }));

            if (datat) setTacts(datat);
            if (data1) setStar1(data1);
            if (dataS) setStarS(dataS);
        }
        //}

        if (datat) setTacts(datat);
        if (data1) setStar1(data1);
        if (dataS) setStarS(dataS);

        const tips = await fetchTipsData('student')
        setTipsTextDataSub(tips)

        //для проверки нового стора (SubjectsStore)
        //subjects = [...student.studentSubjects]


    }


    const chartSize = 450;
    const numberOfScale = 5;

    const saveSubjectData = async () => {
        try {
            const formData = new FormData()
            formData.append('starData', JSON.stringify(starS[0]?.star))
            console.log("saveSubjectData", formData);

            modyfyStarSubData(formData).then(data => {
                //console.log("DATA",data,data == 'ok');
                if (data == 'ok') {
                    message.success("Cохранено успешно!")
                } else {
                    message.error("Не сохранено, ответ сервера:", data)
                    //message.info('This is a normal message');
                }
            })
        } catch (error) {
            message.error("Не сохранено, ошибка:", error.message)
        }
    }

    const saveEducationRouteData = async (star1) => {
        try {
            const formData = new FormData()
            //todo убрать лишние данные
            formData.append('starData', JSON.stringify(star1))
            modyfyStarEduData(formData).then(data => {
                //console.log("DATA",data,data == 'ok');
                if (data == 'ok') {
                    message.success("Cохранено успешно!")
                } else {
                    message.error("Не сохранено, ответ сервера:", data)
                    //message.info('This is a normal message');
                }
            })
        } catch (error) {
            message.error("Не сохранено, ошибка:", error.message)
        }
    }

    const navigate = (name) => {
        if (mode == 'student') {
            let subject = student.studentSubjects?.find((i) => i.name == name)
            if (subject) {
                student.setSelectedSubject(subject)
                student.setSelectedSubMenu({ id: 41 })
            } else {
                message.error("Переход звезду 2 не удается")
            }
        }
        if (mode == 'parent') {
            let subject = parent.studentSubjects?.find((i) => i.name == name)
            if (subject) {
                parent.setSelectedSubject(subject)
                parent.setSelectedSubMenu({ id: 41 })
            } else {
                message.error("Переход звезду 2 не удается")
            }
        }
    }

    const handleChangeTacts = async (value) => {
        var data = [[], []];
        if (mode == 'student') {
            data = await fetchStar1Data(value);
        } else {
            if (parent.studentData.id) {
                data = await fetchStar1DataBSID(parent.studentData.id, value)
            }

        }
        setStar1(data);

    };

    const handleChangeSkillTacts = async (value) => {
        console.log("handleChangeSkillTacts", value);
        setSelectedSkillTacts(value);
        var data = [[], []];
        if (mode == 'student') {
            const dataS = await fetchStarSData('')
            if (dataS) setStarS(dataS);

        } else {
            if (parent.studentData.id) {
                const dataS = await fetchStarSDataBSID(parent.studentData.id, value)
                if (dataS) setStarS(dataS);
                //data = await fetchSkillTacts(parent.studentData.id, value)
            }

        }
        setStar1(data);

    };

    return (
        <>
            <Card style={{ maxWidth: '1000px', padding: '8' }} key={"star" + parent?.studentData?.id}
                title={
                    <>
                        <div style={{ fontSize: '20px', color: "#75B53E", }}>Индивидуальный образовательный маршрут</div>
                        <div style={{ width: '100%', }}>
                            <Select
                                showSearch={false}
                                mode='multiple'
                                placeholder='Выберите такт'
                                options={tacts}
                                style={{ width: '100%' }}
                                onChange={handleChangeTacts}
                            />
                        </div>
                    </>
                }
                size='small'
            >
                {/*             <RadarChart
                route={student.educationRoute}
                size={chartSize}
                numberOfScale={numberOfScale}
            />
            <RadarChart
                route={starS}
                size={chartSize}
                numberOfScale={numberOfScale}
            /> */}
                {star1.length > 0 &&
                    <>
                        {star1.map((item, index) =>
                            <EditStar
                                key={index}
                                header={item.title}
                                starS={item.star}
                                setStarS={setStar1}
                                starLayer={1}
                                navigate={navigate}
                                saveData={saveEducationRouteData}
                                greenDesc='- планируемый уровень'
                                yellowDesc='- текущий уровень'
                                //editable={mode == 'parent' ? false : item.editable}
                                editable={false}
                            />
                        )}
                        {/*                         <Image style={{ marginTop: '15px', marginBottom: "15px", maxWidth: '100%' }}
                            height='540'
                            //srcSet='{heightsSG540} 630w'
                            //sizes='(min-width: 1200px) 630w,(max-width: 1199px) 610w' //,(max-width: 380px) 350w
                            src={heightsSG}
                        /> */}
                    </>
                }

            </Card>

            <PsychologicalReport key={'PsychologicalReport'+studentID}/>

            <ParentSkillStars
                //key = {"key"+admin.selectedStudent?.id}
                //studentID={parent?.studentData?.id}
                studentID
                skillTacts={skillTacts}
                handleChangeSkillTacts={handleChangeSkillTacts}
                setStarS={saveSubjectData}
                saveData={saveSubjectData}
                starS={starS}
                tipsTextData={tipsTextDataSub}
                editable={false}
            //editable={mode == 'parent' ? false : starS?.editable}
            />

            {/*             <Card style={{ maxWidth: '1000px', padding: '8' }}
                title={<div style={{ fontSize: '20px', color: "#75B53E", textJustify: 'center' }}>Индивидуальный план развития</div>}
                size='small'
            >
                {starS.length > 0 &&
                    <EditStar
                        starS={starS}
                        setStarS={setStarS}
                        tipsTextData={tipsTextDataSub}
                        starLayer={1}
                        saveData={saveSubjectData}
                        greenDesc='- самооценка'
                        yellowDesc='- оценка тьютора'
                        editable={mode == 'parent' ? false : true}
                    />
                }
            </Card> */}
        </>
    );
});

export default ParentStars;