import { message } from "antd";
import { $authHost } from ".";

class ClassAPI {

    _url: string = 'api/';

    constructor(url: string) {
        this._url += url;
        //console.log("ClassAPI", this._url);

    }

    async get(params: { url: string }) {
        const { url } = params;
/*         for (const param in params) {

        } */
        try {
            const { data } = await $authHost.get(`${this._url}/${url}`)
            return data
        } catch (e) {
            message.error("Ошибка запроса GET!");
            return null;
        }

    }

    async post(params: { url: string, body:any }) {
        const { url, body } = params;
/*         for (const param in params) {

        } */
        try {
            const { data } = await $authHost.post(`${this._url}/${url}/`, body)
            return data
        } catch (e) {
            message.error("Ошибка запроса POST!");
            return null;
        }

    }
}

export default ClassAPI