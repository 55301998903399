import React from 'react';
import { CheckCircleTwoTone, CheckSquareTwoTone, CloseCircleTwoTone, CloseSquareTwoTone } from '@ant-design/icons';
import { Tooltip } from 'antd';

const statuses = { green: 'Присутствовал', red: 'Не явился', yellow: 'Опоздал', orange: 'Ушел', blue: 'Дистант' }

const titleGenEl = (el) => {

  return ((statuses[el.status]) ? statuses[el.status] : '') + ((el.begin && el.end) ? ', ' + el.begin + '-' + el.end : '') + ((el.comment) ? ', ' + el.comment : '');
}

const titleGenCell = (cell) => {
  return statuses[cell.status] + ', ' + cell.comment
}

const AttendanceStudentStatus = ({ cell }) => {
  //  cell:{'status':'red','comment':'dgfdghdfgdfg'}

  if (cell.constructor.name == "Array") {
    return (cell.map((el) => {
      return ItemView(el)
      /*       if (el.status == 'red') {
              if (el.comment) {
                return (<Tooltip title={el.begin + '-' + el.end + ': ' + el.comment} color="grey"><CloseSquareTwoTone twoToneColor="red" /></Tooltip>);
              } else {
                return (<Tooltip title={el.begin + '-' + el.end} color="grey"><CloseCircleTwoTone twoToneColor="red" /></Tooltip>);
              }
            } else {
              if (el.comment) {
                return (<Tooltip title={titleGenEl(el)} color="grey"><CheckSquareTwoTone twoToneColor={el.status} /></Tooltip>);
              } else {
                return (<Tooltip title={titleGenEl(el)} color="grey"><CheckCircleTwoTone twoToneColor={el.status} /></Tooltip>);
              }
            } */
    }))
  } else {
    return ItemView(cell)
    /* if (cell.status == 'red') {
      if (cell.comment) {
        return (<Tooltip title={cell.comment} color="grey"><CloseSquareTwoTone twoToneColor="red" /></Tooltip>);
      } else {
        return (<Tooltip title={cell.comment} color="grey"><CloseCircleTwoTone twoToneColor="red" /></Tooltip>);
      }
    } else {
      if (cell.comment) {
        return (<Tooltip title={titleGenCell(cell)} color="grey"><CheckSquareTwoTone twoToneColor={cell.status} /></Tooltip>);
      } else {
        return (<Tooltip title={titleGenCell(cell)} color="grey"><CheckCircleTwoTone twoToneColor={cell.status} /></Tooltip>);
      }
    } */
  }
};

const ItemView = (item) => {
/*   if (item.status == 'red') {
    if (item.comment) {
      return (<Tooltip title={titleGenEl(item)} color="grey"><CloseSquareTwoTone twoToneColor="red" /></Tooltip>);
    } else {
      return (<Tooltip title={titleGenEl(item)} color="grey"><CloseCircleTwoTone twoToneColor="red" /></Tooltip>);
    }
  } else { */
    if (item.comment) {
      return (<Tooltip title={titleGenEl(item)} color="grey"><CheckSquareTwoTone twoToneColor={item.status} /></Tooltip>);
    } else {
      return (<Tooltip title={titleGenEl(item)} color="grey"><CheckCircleTwoTone twoToneColor={item.status} /></Tooltip>);
    }
  /* } */
}

export default AttendanceStudentStatus;