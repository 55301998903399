import { useLoaderData } from "react-router-dom"
import FilesUpload from "./utilites/FilesUpload"
import { Card } from "antd"

export const loderPsychologicalReport = async () => {
    return { fileList: [], selectedStudent: 0 }
}


export const PsychologicalReport = ({ isDisabled = true }) => {
    const { fileList, selectedStudent } = useLoaderData() as any;

//    console.log("PsychologicalReport", isDisabled, fileList, selectedStudent);

    if (isDisabled && (!fileList || fileList.length === 0)) {
        return (<></>)
    }

    return (
        <Card title={'Запуск во II полугодие'}>
            <FilesUpload
                disabled={isDisabled}
                fileList={fileList}
                action={`/api/file/upload/?area=${'PsychologicalReport'}&id=${selectedStudent}`}
            />
        </Card>
    )
}

