
import { redirect, useLoaderData, useSubmit } from "react-router-dom"
import Select from 'react-select'
import styled from 'styled-components'
import { fetchClasses } from "../http/adminApi"



export async function selectClassLoader(params: any) {
    const selectedClass = Number(params?.params['selectedClass'] || 0);
    const rawClasses = await fetchClasses();
    const classes = rawClasses.map((_class: any) => { return ({ value: _class.id, label: _class.name }) })
    //console.log("selectClassLoader", params, classes);
    return { classes, selectedClass };
}

export async function selectClassAction({ params, request }: any) {
    const formData = await request.formData();

/*     const { query } = Object.fromEntries(formData);
    if (query) console.log("modules2Action", JSON.parse(query));

 */    const { _class } = Object.fromEntries(formData);
    if (_class) return redirect(`${_class}`);
    //console.log("2", _class);

    /*   console.log("modules actions: ", params, classsubject); */
    return redirect('.')
}

interface SelectOptions {
    value: number
    label: string
}

const StyledSelect = styled(Select)`
    width: fit-content;
    block-size: fit-content;
    z-index: 99999;
    margin: 4px;
`;

export const SelectClass = () => {
    const submit = useSubmit();
    const { classes, selectedClass } = useLoaderData() as { classes: SelectOptions[], selectedClass: number };
    //console.log(classes, selectedClass);


    const onSelect1 = (e: any) => {
        //console.log(e);
        let formData = new FormData();
        formData.append("_class", e?.value);
        //formData.append("classname", e?.label);
        submit(formData, { method: "POST" });
    }

    if (classes) return (
        <StyledSelect
            onChange={onSelect1}
            name="class"
            placeholder='Выберите класс'
            value={classes.filter((item: any) => item.value === selectedClass)}
            options={classes}
        />
    )

    return (<></>)
}
